import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet';
import layoutStyles from '../styles/style.module.scss'
import { Link } from 'gatsby'

const ContributionsPage = () => {
    return (
        <div name="topo">
            <Helmet>
                <meta charSet="utf-8" />
                <title>HP Gregorio</title>
            </Helmet>
            <Layout>

            <div className={layoutStyles.caixa}>
                <p className={layoutStyles.parag}><Link className={layoutStyles.link} to="/contributions#2019">2019</Link> | <Link className={layoutStyles.link} to="/contributions#2018">2018</Link> | <Link className={layoutStyles.link} to="/contributions#2017">2017</Link> | <Link className={layoutStyles.link} to="/contributions#2016">2016</Link> | <Link className={layoutStyles.link} to="/contributions#2015">2015</Link> | <Link className={layoutStyles.link} to="/contributions#2014">2014</Link> | <Link className={layoutStyles.link} to="/contributions#2013">2013</Link> | <Link className={layoutStyles.link} to="/contributions#2012">2012</Link> | <Link className={layoutStyles.link} to="/contributions#2011">2011</Link> | <Link className={layoutStyles.link} to="/contributions#2010">2010</Link> | <Link className={layoutStyles.link} to="/contributions#2009">2009</Link> | <Link className={layoutStyles.link} to="/contributions#2008">2008</Link> | <Link className={layoutStyles.link} to="/contributions#2007">2007</Link> | <Link className={layoutStyles.link} to="/contributions#2006">2006</Link> | <Link className={layoutStyles.link} to="/contributions#2004">2004</Link></p>
            </div>

            <h1 className={layoutStyles.t1} name="2019">2019</h1>
            <Link className={layoutStyles.link} to="/contributions#topo">^topo</Link>
                <nav>
                    <ul className={layoutStyles.indList}>

                        <li className={layoutStyles.t2}>Projeto: EIA/RIMA para o gasoduto Rota 4. Cliente: Mineral Engenharia / Rota 4 Participações. <p className={layoutStyles.parag}>Função: Diagnóstico Ambiental, Análise Integrada, Impactos Ambientais, Análise e gerenciamento de riscos e análise de vulnerabilidade ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Modelagem de vazamentos de óleo para o gasoduto OSSP. Cliente: Egis Engenharia / Petrobras. <p className={layoutStyles.parag}>Função: modelagem hidrodinâmica e de óleo.</p></li>
                            
                        <li className={layoutStyles.t2}>Projeto: Modelagem de vazamentos de óleo para o gasoduto OSVAT. Cliente: Egis Engenharia / Petrobras. <p className={layoutStyles.parag}>Função: modelagem hidrodinâmica e de óleo.</p></li>
                            
                        <li className={layoutStyles.t2}>Projeto: Plano de emergência individual para a DP World – Santos/SP. Cliente: Milanelli Consultoria/DP World. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>
                            
                        <li className={layoutStyles.t2}>Projeto: Estudo Complementar para a Atividade de Produção e Escoamento de Petróleo e Gás Natural do Polo Pré-Sal da Bacia de Santos – Etapa 3 / FPSO P-70. Cliente: Mineral Engenharia / Petrobras. <p className={layoutStyles.parag}>Função: Análise e gerenciamento de riscos, análise de vulnerabilidade ambiental.</p></li>
                            
                        <li className={layoutStyles.t2}>Projeto: EIA/RIMA para a Atividade de Perfuração Marítima nos Blocos BM-BAR-3 e BM-BAR-5 - Bacia de Barreirinha. Cliente: Egis Engenharia / Petrobras. <p className={layoutStyles.parag}>Função: Impactos Ambientais, Análise e gerenciamento de riscos e análise de vulnerabilidade ambiental.</p></li>
                            
                        <li className={layoutStyles.t2}>Projeto: Análise Técnica dos EIAs/RIMAs de Atividade de Perfuração na Foz do Rio Amazonas – BP e QG. Cliente: Greenpeace. <p className={layoutStyles.parag}>Função: Análise Técnica.</p></li>
                            
                        <li className={layoutStyles.t2}>Projeto: Análise de Risco Ambiental para a Estrada de Ferro Carajás. Cliente: Suatrans/Vale. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental e auxilio diverso.</p></li>
                            
                        <li className={layoutStyles.t2}>Projeto: Plano de emergência individual Cereal Sul – Santos/SP. Cliente: Suatrans/Cereal Sul. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>
                            
                        <li className={layoutStyles.t2}>Projeto: Plano de emergência individual Terminal 12-A – Santos/SP. Cliente: Suatrans/ Terminal 12-A. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>
                            
                        <li className={layoutStyles.t2}>Projeto: Plano de emergência individual Yara Brasil Fertilizantes – Canoas/RS. Cliente: Suatrans/Yara Brasil Fertilizantes. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>
                            
                        <li className={layoutStyles.t2}>Projeto: Plano de emergência individual Porto de Rio Grande/RS. Cliente: Suatrans/Porto Rio Grande/RS. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>
                            
                        <li className={layoutStyles.t2}>Projeto: Plano de emergência individual Porto de Itajaí/SC. Cliente: Suatrans/Porto de Itajaí/SC. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>
                            
                        <li className={layoutStyles.t2}>Projeto: Relatório técnico de campanha baseline da qualidade da água e sedimento para gasoduto, emissário submarino, Soft Yoke e FSRU em Sergipe. Cliente: Tetra + / Celse. <p className={layoutStyles.parag}>Função: Diagnóstico Ambiental de Qualidade de Água e Sedimentos.</p></li>
                            
                        <li className={layoutStyles.t2}>Projeto: Plano de emergência individual Yara Brasil Fertilizantes – Porto Alegre/RS. Cliente: Suatrans/Yara Brasil Fertilizantes. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>
                            
                        <li className={layoutStyles.t2}>Projeto: Plano de emergência individual para a RUMO – Santos/SP. Cliente: Milanelli Consultoria/RUMO. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Programa de Monitoramento da Qualidade da água da Praia do Jatobá. Cliente: Tetra + / Celse. <p className={layoutStyles.parag}>Função: Diagnóstico Ambiental – monitoramento mensal (janeiro a maio/2019).</p></li>
                                
                        <li className={layoutStyles.t2}>Projeto: Análise de Vulnerabilidade do Bloco Alto de Cabo Frio Central. Cliente: Egis Engenharia / Petrobras. <p className={layoutStyles.parag}>Função: Análise de vulnerabilidade ambiental.</p></li>
                    </ul>
                </nav>
                <p><br></br></p>

            <h1 className={layoutStyles.t1} name="2018">2018</h1>
                <Link className={layoutStyles.link} to="/contributions#topo">^topo</Link>
                <nav>
                    <ul className={layoutStyles.indList}>

                        <li className={layoutStyles.t2}>Projeto: Modelagem hidrodinâmica e de dispersão dos efluentes da UTE Lins no Rio Dourado. Cliente: Mineral Engenharia / Omega Engenharia / UTE Lins. <p className={layoutStyles.parag}>Função: Modelagem hidrodinâmica e dispersão de efluentes.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Programa de Monitoramento da Qualidade da água da Praia do Jatobá. Cliente: Tetra + / Celse. <p className={layoutStyles.parag}>Função: Diagnóstico Ambiental – monitoramento mensal (abril a dezembro/2018).</p></li>

                        <li className={layoutStyles.t2}>Projeto: Modelagem hidrodinâmica, de dispersão de óleo e sedimentos para a TUP Enseado do Malato. Cliente: Salt/TUP Enseada do Malato. <p className={layoutStyles.parag}>Função: Modelagem hidrodinâmica, dispersão de óleo e sedimentos.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Plano de emergência individual para a TUP Enseado do Malato. Cliente: Milanelli Consultoria/TUP Enseada do Malato. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Plano de emergência individual para a CELSE. Cliente: Milanelli Consultoria/TUP Enseada do Malato. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Modelagem hidrodinâmica e de ondas para o Saco da Capela – Ilhabela/SP. Cliente: Salt. <p className={layoutStyles.parag}>Função: Modelagem hidrodinâmica e de ondas.</p></li>

                        <li className={layoutStyles.t2}>Projeto: EIA/RIMA para a Atividade de Perfuração Marítima no Bloco BM-BAR-5 Bacia de Barreirinha. Cliente: Egis Engenharia / Petrobras. <p className={layoutStyles.parag}>Função: Análise e gerenciamento de riscos e análise de vulnerabilidade ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Plano de Emergência Individual QGI Brasil. Cliente: Milanelli Consultoria / Suatrans / Queiroz Galvão. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Avaliação do impacto hidrodinâmico sobre as ondas devido a presença do terminal FSRU – São Francisco do Sul/SC. Cliente: Salt / Golar Power. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental e modelagem de ondas.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Plano de Emergência Individual Poly Terminais Portuários. Cliente: Milanelli Consultoria / Suatrans / Poly Terminais. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>

                    </ul>
                </nav>
                <p><br></br></p>

                <h1 className={layoutStyles.t1} name="2017">2017</h1>
                <Link className={layoutStyles.link} to="/contributions#topo">^topo</Link>
                <nav>
                    <ul className={layoutStyles.indList}>

                        <li className={layoutStyles.t2}>Projeto: EIA/RIMA para a Atividade de Perfuração Marítima no Bloco BM-BAR-3 Bacia de Barreirinha. Cliente: Egis Engenharia / Petrobras. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, análise e gerenciamento de riscos, análise de vulnerabilidade ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Plano de Emergência Individual do Terminal de Rio Grande. Cliente: Milanelli Consultoria / Suatrans / Braskem. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Plano de Emergência Individual do Terminal de Santa Clara. Cliente: Milanelli Consultoria / Suatrans / Braskem. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Climatologia da temperatura, umidade relativa e intensidade do vento, no período diurno do mês de agosto, ao largo de Santos. Cliente: Milanelli Consultoria / CODESP. <p className={layoutStyles.parag}>Função: Diagnóstico Ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Estudo da Modelagem de Dispersão do Salmouroduto em Aracajú/SE. Cliente: Salt Ambiental. <p className={layoutStyles.parag}>Função: Modelagem hidrodinâmica, Modelagem de Qualidade de água.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Estudo da Modelagem Morfológica na área do Gasoduto e Adutora em Aracajú/SE. Cliente: Salt Ambiental. <p className={layoutStyles.parag}>Função: Modelagem hidrodinâmica, modelagem de deposição e erosão de sedimentos.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Plano de Emergência Individual da Enseada Industria Naval. Cliente: Milanelli Consultoria / Enseada Industria Naval. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Plano de Emergência Individual do Terminal Ponta da Madeira. Cliente: Milanelli Consultoria / Vale do Rio Doce. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Análise Técnica dos EIAs/RIMAs de Atividade de Perfuração na Foz do Rio Amazonas. Cliente: Greenpeace. <p className={layoutStyles.parag}>Função: Análise Técnica.</p></li>

                        <li className={layoutStyles.t2}>Projeto: EIA/RIMA para a Atividade de Perfuração Marítima no Bloco BM-CAL-10 Bacia de Camamu Almada. Cliente: Egis Engenharia / Petrobras. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, análise e gerenciamento de riscos, análise de vulnerabilidade ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Plano de Emergência Individual VLI - Ceará. Cliente: Milanelli Consultoria / VLI. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: EIA/RIMA para a Atividade de Perfuração Marítima no Bloco BM-CAL-11 e BM-CAL-12 Bacia de Camamu Almada. Cliente: Egis Engenharia / Petrobras. <p className={layoutStyles.parag}>Função: Análise e gerenciamento de riscos, análise de vulnerabilidade ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: EIA/RIMA para a Atividade de Perfuração Marítima nos Blocos BM-PEPB-1 e BM-PEPB-3 Bacia de Pernambuco-Paraíba. Cliente: Egis Engenharia / Petrobras. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, análise e gerenciamento de riscos, análise de vulnerabilidade ambiental.</p></li>

                    </ul>
                </nav>
                <p><br></br></p>

                <h1 className={layoutStyles.t1} name="2016">2016</h1>
                <Link className={layoutStyles.link} to="/contributions#topo">^topo</Link>
                <nav>
                    <ul className={layoutStyles.indList}>

                        <li className={layoutStyles.t2}>Projeto: EIA/RIMA para a Atividade de Produção e Escoamento de Petróleo e Gás Natural do Polo Pré-Sal da Bacia de Santos – Etapa 3. Cliente: Mineral Engenharia / Petrobras. <p className={layoutStyles.parag}>Função: Análise integrada, impactos ambientais, prognóstico ambiental, análise e gerenciamento de riscos, análise de vulnerabilidade ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Estudo da Modelagem Hidrodinâmica para avaliação do nível do Rio São Francisco em Mato Dentro – BA. Cliente: Salt Ambiental. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Plano de Emergência Individual – Terminal Granel Química Ltda – Rio Grande/RS. Cliente: Milanelli Consultoria / TEGRAM. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Caracterização Meteorológica, Oceanográfica e Modelagem Hidrodinâmica e de Qualidade de Água para atividade de Maricultura em Guarapari - ES. Cliente: Egis / UFES. <p className={layoutStyles.parag}>Função: Diagnóstico Ambiental, modelagem hidrodinâmica e de qualidade de água.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Confecção de shape de ecossistemas costeiros para a costa entre Paripueira-AL e Fortaleza-CE. Cliente: Egis. <p className={layoutStyles.parag}>Função: Diagnóstico Ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Estudo da Modelagem da Dispersão de Sedimentos da atividade de mineração no Monte HotSpur - BA. Cliente: Salt Ambiental. <p className={layoutStyles.parag}>Função: Modelagem hidrodinâmica e de transporte de sedimentos.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Estudo de usos socioeconômicos das áreas de manguezal – APAMLN. Cliente: Mineral Engenharia / Petrobras. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Confecção de shape de ecossistemas costeiros para a costa entre Rio de Janeiro-RJ e Fortaleza-CE. Cliente: Egis. <p className={layoutStyles.parag}>Função: Diagnóstico Ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Plano de Emergência Individual do porto do Rio Grande (porto velho e porto novo). Cliente: Milanelli Consultoria / Ecosorb. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Revisão da Análise e Gerenciamento de Riscos para a Atividade de Perfuração Marítima no Bloco BM-J-4 e BM-J-5 Bacia de Jequitinhonha. Cliente: Egis Engenharia / Petrobras. <p className={layoutStyles.parag}>Função: Análise e gerenciamento de riscos, análise de vulnerabilidade ambiental.</p></li>

                    </ul>
                </nav>
                <p><br></br></p>

                <h1 className={layoutStyles.t1} name="2015">2015</h1>
                <Link className={layoutStyles.link} to="/contributions#topo">^topo</Link>
                <nav>
                    <ul className={layoutStyles.indList}>

                        <li className={layoutStyles.t2}>Projeto: EIA/RIMA - Atividade de Perfuração Marítima nos Blocos BM-J-4 e BM-J-5 Bacia de Jequitinhonha. Cliente: Lenc / Petrobras. <p className={layoutStyles.parag}>Função: análise e gerenciamento de riscos ambientais, análise de vulnerabilidade.</p></li>

                        <li className={layoutStyles.t2}>Projeto: EIA/RIMA - Atividade de Perfuração Marítima nos Blocos BM-CAL9 Bacia de Camamú-Almada. Cliente: Lenc / Petrobras. <p className={layoutStyles.parag}>Função: análise e gerenciamento de riscos ambientais, análise de vulnerabilidade.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Estudo Complementar para a Atividade de Produção e Escoamento de Petróleo e Gás Natural do Polo Pré-Sal da Bacia de Santos – Etapa 2 / FPSO Cidade de Maricá. Cliente: Mineral Engenharia / Petrobras. <p className={layoutStyles.parag}>Função: Impactos ambientais e análise e gerenciamento de riscos, análise de vulnerabilidade.</p></li>

                        <li className={layoutStyles.t2}>Projeto: EIA/RIMA – Pesquisa Sísmica 4D Offshore. Cliente: Mineral Engenharia / Petrobras. <p className={layoutStyles.parag}>Função: diagnóstico ambiental, análise integrada, análise de impactos ambientais.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Plano de Emergência Individual – Terminal Ferroviário de Barcarena. Cliente: Milanelli Consultoria / Suatrans. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>

                    </ul>
                </nav>
                <p><br></br></p>

                <h1 className={layoutStyles.t1} name="2014">2014</h1>
                <Link className={layoutStyles.link} to="/contributions#topo">^topo</Link>
                <nav>
                    <ul className={layoutStyles.indList}>

                        <li className={layoutStyles.t2}>Projeto: Análise de Vulnerabilidade para o FPSO Cidade de São Paulo. Cliente: Mineral Engenharia / Petrobras. <p className={layoutStyles.parag}>Função: Análise de vulnerabilidade ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Análise de Vulnerabilidade para o FPSO Cidade de Paraty. Cliente: Mineral Engenharia / Petrobras. <p className={layoutStyles.parag}>Função: Análise de vulnerabilidade ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Diagnóstico ambiental preliminar para a UTE Castilho. Cliente: Mineral Engenharia / Duke Energy. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Plano básico de monitoramento ambiental – plano de monitoramento de qualidade de água. Cliente: Mineral Engenharia / Intervales. <p className={layoutStyles.parag}>Função: Desenvolvimento de plano de monitoramento.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Estudo Complementar para a Atividade de Produção e Escoamento de Petróleo e Gás Natural do Polo Pré-Sal da Bacia de Santos – Etapa 1 / FPSO Cidade de Itaguaí. Cliente: Mineral Engenharia / Petrobras. <p className={layoutStyles.parag}>Função: Impactos ambientais, análise e gerenciamento de riscos e análise de vulnerabilidade ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Plano de Emergência Individual – Enseada Naval, Unidade Paraguaçu. Cliente: Milanelli Consultoria / Enseada. <p className={layoutStyles.parag}>Função: Análise de Vulnerabilidade Ambiental.</p></li>

                    </ul>
                </nav>
                <p><br></br></p>

                <h1 className={layoutStyles.t1} name="2013">2013</h1>
                <Link className={layoutStyles.link} to="/contributions#topo">^topo</Link>
                <nav>
                    <ul className={layoutStyles.indList}>

                        <li className={layoutStyles.t2}>Projeto: EIA/RIMA para a Atividade de Produção e Escoamento de Petróleo e Gás Natural do Polo Pré-Sal da Bacia de Santos – Etapa 2. Cliente: Petrobras. Execução: Mineral Engenharia e Meio Ambiente. <p className={layoutStyles.parag}>Função: diagnóstico ambiental, análise integrada, impactos ambientais, prognóstico ambiental, análise e gerenciamento de riscos, análise de vulnerabilidade ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Diagnóstico da Biodiversidade no entorno da UN-LUBNOR. Cliente: Petrobras. Execução: Mineral Engenharia e Meio Ambiente. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: EIA/RIMA – Unidade de Gasolina Guamaré Cliente: Petrobras. Execução: Mineral Engenharia e Meio Ambiente. <p className={layoutStyles.parag}>Função: Análise e gerenciamento de riscos ambientais.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Estudo da dispersão dos efluentes do Terminal Aquaviário de Angra dos Reis. Cliente: Transpetro. Execução: Mineral Engenharia e Meio Ambiente. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de água.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Análise de Vulnerabilidade da Plataforma e sistema de escoamento de gás de Mexilhão – Bacia de Santos – Plano de Emergência Individual – PEI. Cliente: Petrobras. Execução: Mineral Engenharia e Meio Ambiente. <p className={layoutStyles.parag}>Função: Análise de vulnerabilidade ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Análise de Vulnerabilidade da Área Geográfica da Bacia de Santos – AGBS – Plano de Emergência Individual – PEI. Cliente: Petrobras. Execução: Mineral Engenharia e Meio Ambiente. <p className={layoutStyles.parag}>Função: Análise de vulnerabilidade ambiental.</p></li>

                    </ul>
                </nav>
                <p><br></br></p>

                <h1 className={layoutStyles.t1} name="2012">2012</h1>
                <Link className={layoutStyles.link} to="/contributions#topo">^topo</Link>
                <nav>
                    <ul className={layoutStyles.indList}>

                        <li className={layoutStyles.t2}>Projeto: Estudo Complementar para a Atividade de Produção e Escoamento de Petróleo e Gás Natural do Polo Pré-Sal da Bacia de Santos – Etapa 1 / FPSO Cidade de Paraty. Cliente: Petrobras. Execução: Mineral Engenharia e Meio Ambiente. <p className={layoutStyles.parag}>Função: Impactos ambientais e análise e gerenciamento de riscos.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Estudo Complementar para a Atividade de Produção e Escoamento de Petróleo e Gás Natural do Polo Pré-Sal da Bacia de Santos – Etapa 1 / FPSO Cidade de São Paulo. Cliente: Petrobras. Execução: Mineral Engenharia e Meio Ambiente. <p className={layoutStyles.parag}>Função: Impactos ambientais e análise e gerenciamento de riscos.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Estudo Complementar para a Atividade de Produção e Escoamento de Petróleo e Gás Natural do Polo Pré-Sal da Bacia de Santos – Etapa 1 / FPSO Cidade de Mangaratiba. Cliente: Petrobras. Execução: Mineral Engenharia e Meio Ambiente. <p className={layoutStyles.parag}>Função: Impactos ambientais e análise e gerenciamento de riscos.</p></li>

                        <li className={layoutStyles.t2}>Projeto: EIA/RIMA - Projeto TLD de Franco 1, Cessão Onerosa Pré Sal, Bacia de Santos. Cliente: Petrobras. Execução: Mineral Engenharia e Meio Ambiente. <p className={layoutStyles.parag}>Função: diagnóstico ambiental, análise integrada, impactos ambientais, prognóstico ambiental, análise e gerenciamento de riscos.</p></li>

                    </ul>
                </nav>
                <p><br></br></p>

                <h1 className={layoutStyles.t1} name="2011">2011</h1>
                <Link className={layoutStyles.link} to="/contributions#topo">^topo</Link>
                <nav>
                    <ul className={layoutStyles.indList}>

                        <li className={layoutStyles.t2}>Projeto: EIA/RIMA - Projeto de Escoamento de Gás para Cabiúnas - Rota Cabiúnas, Bacias de Santos e Campos. Cliente: Petrobras. Execução: Mineral Engenharia e Meio Ambiente. <p className={layoutStyles.parag}>Função: diagnóstico ambiental, análise de impactos ambientais.</p></li>

                        <li className={layoutStyles.t2}>Projeto: EIA/RIMA - Gasodutos OCVAP I e II. Cliente: Transpetro. Execução: Mineral Engenharia e Meio Ambiente. <p className={layoutStyles.parag}>Função: diagnóstico ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Relatório Anual Consolidado do monitoramento das condições de licenciamento do duto de álcool e derivativos no trecho entre Paulínea e Ribeirão Preto - SP. Cliente: Logum Logística. Execução: Mineral Engenharia e Meio Ambiente. <p className={layoutStyles.parag}>Função: diagnostico ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Estudo sobre os possíveis impactos dos efluentes do Terminal Aquaviário de São Sebastião – TASSE. Cliente: Transpetro. Execução: Mineral Engenharia e Meio Ambiente. <p className={layoutStyles.parag}>Função: diagnóstico ambiental, análise integrada, impactos ambientais, modelagem hidrodinâmica e de qualidade de água.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Análise de dados do Projeto PEMCA. Execução: Laboratório de Hidrodinâmica Costeira, Instituto Oceanográfico da Universidade de São Paulo. Cliente: Statoil. <p className={layoutStyles.parag}>Função: Análise de Dados.</p></li>
                    </ul>
                </nav>
                <p><br></br></p>

                <h1 className={layoutStyles.t1} name="2010">2010</h1>
                <Link className={layoutStyles.link} to="/contributions#topo">^topo</Link>
                <nav>
                    <ul className={layoutStyles.indList}>
                        <li className={layoutStyles.t2}>Projeto: Condições Hidrodinâmicas e Modelagem do Largo de Santa Rita – Santos/SP – Brasil. Cliente: Santa Rita Terminais Portuários. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Estudio de impacto ambiental del proyecto de perforación exploratoria de hidrocarburos en aguas marinas costa afuera colombiana en el bloque RC9 de Ecopetrol S.A. Cliente: Ecopetrol. Execução: Consórcio GeoHidroEco. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica, de óleo e fluído de perfuração, impactos ambientais e análise de riscos.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Estudio de impacto ambiental del proyecto de perforación exploratoria de hidrocarburos en aguas marinas costa afuera colombiana en el bloque RC11 de Ecopetrol S.A. Cliente: Ecopetrol. Execução: Consórcio GeoHidroEco. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica, de óleo e fluído de perfuração, impactos ambientais e análise de riscos.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Estudio de impacto ambiental del proyecto de perforación exploratoria de hidrocarburos en aguas marinas costa afuera colombiana en el bloque RC12 de Ecopetrol S.A. Cliente: Ecopetrol. Execução: Consórcio GeoHidroEco. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica, de óleo e fluído de perfuração, impactos ambientais e análise de riscos.</p></li>
                    </ul>
                </nav>
                <p><br></br></p>

                <h1 className={layoutStyles.t1} name="2009">2009</h1>
                <Link className={layoutStyles.link} to="/contributions#topo">^topo</Link>
                <nav>
                    <ul className={layoutStyles.indList}>
                        <li className={layoutStyles.t2}>Projeto: Estudo da Hidrodinâmica e das Condições Dispersivas dos sedimentos no Rio Pinheiros e na Represa Billings. São Paulo – Brasil. Relatórios Preliminares I e II. Cliente: Cobrape. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de sedimentos.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Estudo das condições de Emissão e Dispersão de efluentes no Rio São Francisco, MG – Brasil. Cliente: Votorantim Metais Zinco. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de água.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Modelagem Hidrodinâmica e de Transporte de Sedimentos na Baía de Guanabara e Região Costeira Adjacente. Cliente: Fundespa. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de sedimentos.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Estudos do Campo de Toxicidade provocado pelo efluente industrial emitido pela Votorantim Metais Zinco, Unidade Três Maria, no Rio São Francisco, MG. Cliente: Votorantim Metais Zinco. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de água.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Modelagem da simulação da trajetória e dispersão da Mancha de Óleo decorrente de vazamentos - Terminal Guamaré, RN. Cliente: Mineral Engenharia / Transpetro. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de óleo.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Relatório Técnico Final análise de dados correntográficos Projeto Pro Abrolhos. Cliente: Petrobras. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Análise de dados.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Modelagem Hidrodinâmica e de Dispersão no Canal de São Sebastião/SP – Brasil. Cliente: Fundespa. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de água.</p></li>
                    </ul>
                </nav>
                <p><br></br></p>

                <h1 className={layoutStyles.t1} name="2008">2008</h1>
                <Link className={layoutStyles.link} to="/contributions#topo">^topo</Link>
                <nav>
                    <ul className={layoutStyles.indList}>
                        <li className={layoutStyles.t2}>Projeto: Hydrodynamic Condition of the Region of CM471 and CM473 blocks, Campos Basin, Brazil. Cliente: Shell. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Analysis of oceanographic and meteorological data moorings BC-10 and BS-4. Cliente: Devon. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Estudo da Zona de Mistura Provocada pelo Efluente Industrial emitido pela Votorantim Metais Zinco, Unidade Três Marias - MG (VMTM). Cliente: Votorantim Metais Zinco. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de água.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Estudo das Condições Hidrodinâmicas e Dispersivas para os sedimentos do Rio São Francisco, trecho entre Três Marias e Rio Abaeté (MG). Cliente: Votorantim Metais Zinco. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de sedimento.</p></li>
                    </ul>
                </nav>
                <p><br></br></p>

                <h1 className={layoutStyles.t1} name="2007">2007</h1>
                <Link className={layoutStyles.link} to="/contributions#topo">^topo</Link>
                <nav>
                    <ul className={layoutStyles.indList}>
                        <li className={layoutStyles.t2}>Projeto: Modelagem Hidrodinâmica e de Qualidade de Água do Corpo Receptor do Efluente da Refinaria Henrique Lage (REVAP) - Rio Paraíba - SP. Cliente: Petrobras. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de água.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Modelagem Hidrodinâmica e de Qualidade de Água do Corpo Receptor do Efluente da Refinaria Presidente Bernardes (RPBC) - Rio Cubatão - SP. Cliente: Petrobras. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de água.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Modelagem Hidrodinâmica e de Qualidade de Água do Corpo Receptor do Efluente da Unidade de Negócio de Industrialização do Xisto (SIX) - Córregos Cachoeira e Canoas e Rio Iguaçu - PR. Cliente: Petrobras. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de água.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Modelagem Hidrodinâmica e de Qualidade de Água do Corpo Receptor do Efluente da Refinaria Isaac Sabbé (REMAN) - Rio Negro - AM. Cliente: Petrobras. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de água.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Modelagem Hidrodinâmica e de Qualidade de Água do Corpo Receptor do Efluente da Fábrica de Fertilizantes Nitrogenados (FAFEN) - Praia Aruana - SE. Cliente: Petrobras. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de água.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Modelagem Hidrodinâmica e de Qualidade de Água do Corpo Receptor do Efluente da Lubrificantes e Derivados de Petróleo do Nordeste (LUBNOR) - Enseada do Mucuripe - CE. Cliente: Petrobras. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de água.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Modelagem Hidrodinâmica e de Qualidade de Água do Corpo Receptor do Efluente da Refinaria de Capuava (RECAP) - Rio Tamanduateí - SP. Cliente: Petrobras. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de água.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Modelagem Hidrodinâmica e de Qualidade de Água do Corpo Receptor do Efluente da Refinaria Duque de Caxias (REDUC) - Rio Iguaçu - RJ. Cliente: Petrobras. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de água.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Modelagem Hidrodinâmica e de Qualidade de Água do Corpo Receptor do Efluente da Refinaria Gabriel Passos (REGAP) - Córrego dos Pintados / Lagoa de Ibirité - MG. Cliente: Petrobras. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de água.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Modelagem Hidrodinâmica e de Qualidade de Água do Corpo Receptor do Efluente da Lundolpho Alves (RLAM) - Baía de Todos os Santos - BA. Cliente: Petrobras. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de água.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Modelagem Hidrodinâmica e de Qualidade de Água do Corpo Receptor do Efluente da Refinaria Presidente Getúlio Vargas (REPAR) - Rio Barigui - PR. Cliente: Petrobras. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de água.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Modelagem Hidrodinâmica e de Qualidade de Água do Corpo Receptor do Refinaria de Paulínea (REPLAN) - Rio Atibaia - SP. Cliente: Petrobras. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de água.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Características de Aquecimento e Evaporação dos Lagos Artificiais do Complexo Turístico Residencial e Hoteleiro Balbino Beach e Resort, Ceará, Brasil. Cliente: Geasanevita Engenharia. Execução: Hidromares. <p className={layoutStyles.parag}>Função: Diagnóstico ambiental, modelagem hidrodinâmica e de qualidade de água.</p></li>
                        </ul>
                    </nav>
                <p><br></br></p>

                <h1 className={layoutStyles.t1} name="2006">2006</h1>
                <Link className={layoutStyles.link} to="/contributions#topo">^topo</Link>
                <nav>
                    <ul className={layoutStyles.indList}>
                        <li className={layoutStyles.t2}>Projeto: Estudo das correntes da Baía do Almirantado, Antártica, durante o verão. Execução: Laboratório de Hidrodinâmica Costeira, Instituto Oceanográfico da Universidade de São Paulo. <p className={layoutStyles.parag}>Função: Coleta de Dados, Análise de Dados, Diagnóstico Ambiental.</p></li>

                        <li className={layoutStyles.t2}>Projeto: Estudo de massas de água e correntes da Baía do Almirantado, Antártica, durante o verão. Execução: Laboratório de Hidrodinâmica Costeira, Instituto Oceanográfico da Universidade de São Paulo. <p className={layoutStyles.parag}>Função: Análise de Dados, Diagnóstico Ambiental.</p></li>
                        </ul>
                    </nav>
                <p><br></br></p>

                <h1 className={layoutStyles.t1} name="2004">2004</h1>
                <Link className={layoutStyles.link} to="/contributions#topo">^topo</Link>
                <nav>
                    <ul className={layoutStyles.indList}>
                        <li className={layoutStyles.t2}>Projeto: Análise dados do subprojeto modelagem da qualidade de água da Baía do Almirantado (MODQUALI) - Antártica. Execução: Laboratório de Hidrodinâmica Costeira, Instituto Oceanográfico da Universidade de São Paulo. <p className={layoutStyles.parag}>Função: Análise de Dados.</p></li>
                    </ul>
                </nav>
                
            </Layout>
        </div>
    )    
}

export default ContributionsPage